import React from 'react'
import Image from 'Image'

const TourSponsors = ({ sponsors }) => (
  <span className='c-tour-sponsors u-margin-bottom-small u-dis-b'>
    <h2 className='e-h5 u-margin-bottom-tiny'>Sponsored by...</h2>
    <ul className='o-cleanlist o-list-inline'>
      {
        sponsors.map(({ name, logo }) =>
          <li className='c-tour-sponsors__item' key={name}>
            <span className='u-hide-visually'>{name}</span>
            <Image image={logo} width={419} alt={name} />
          </li>
        )
      }
    </ul>
  </span>
)

export default TourSponsors
