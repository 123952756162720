import React, { Component, Fragment } from 'react'
import { graphql } from 'gatsby'

class TourDates extends Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (event, { location, venue, date, ticketUrl }) {
    if (window && window.fbq) {
      window.fbq('trackCustom', 'ClickTourCTA', {
        location, venue, date, ticketUrl
      })
    }

    return true
  }

  render () {
    const { dates } = this.props

    return (
      <Fragment>
        {
          dates.map(({ location, venue, date, ticketUrl }, tourKey) =>
            <article className='c-tour-grid__row' key={`location_${tourKey}`}>
              <span className='c-tour-grid__item c-tour-grid__item--location'>{venue}, {location}</span>
              <span className='c-tour-grid__item c-tour-grid__item--date'>{date}</span>
              <span className='c-tour-grid__item c-tour-grid__item--tickets'>
                <a
                  href={ticketUrl}
                  rel='noopener noreferrer'
                  target='_blank'
                  className='c-btn c-tour-grid__btn'
                  onClick={(event) => this.handleClick(event, { location, venue, date, ticketUrl })}
                >Buy tickets</a>
              </span>
            </article>
          )
        }
      </Fragment>
    )
  }
}

export default TourDates

export const query = graphql`
  fragment TourDatesFragment on MarkdownRemarkFrontmatter {
    dates {
      date(formatString: "MMMM D, YYYY")
      location
      venue
      ticketUrl
    }
  }
`
