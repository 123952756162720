import React from 'react'

const TourQuote = ({ body, author }) => (
  <blockquote className='c-tour-quote'>
    <div className='c-tour-quote__main o-rte'>
      <p>"{body}"</p>
    </div>
    <footer className='c-tour-quote__footer o-rte'>
      <p>{author}</p>
    </footer>
  </blockquote>
)

export default TourQuote
