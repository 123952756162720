import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'Layout'
import View from 'Tour'
import SEO from 'SEO'

const TourTemplate = ({ data }) => {
  const { markdownRemark } = data
  const {
    frontmatter,
    fields
  } = markdownRemark

  return (
    <Layout>
      <SEO
        title={`${frontmatter.title}`}
        description={`Buy tickets for ${frontmatter.title} shows at Stakhanov.`}
        slug={fields.slug}
      />
      <View {...frontmatter} />
    </Layout>
  )
}

export default TourTemplate

export const query = graphql`
  query (
    $id: String!
  ) {
    markdownRemark(
      id: {
        eq: $id
      }
    ) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        image
        mobileImage
        sponsors {
          name
          logo
        }
        quote {
          body
          author
        }
        show {
          frontmatter {
            hex
          }
        }
        ...TourDatesFragment
      }
    }
  }
`
