import React from 'react'
import PropTypes from 'prop-types'

import Image from 'Image'

import Dates from './TourDates'
import Quote from './TourQuote'
import Sponsors from './TourSponsors'

const Tour = ({ title, show, dates, image, sponsors, mobileImage, quote }) => (
  <div className='c-tour c-hero'>
    <div className='o-container'>
      <div className='o-layout'>
        <div className='o-layout__item u-width-1/2@md u-pull-right@md'>
          <div className='c-tour-content'>
            {
              sponsors &&
                <Sponsors sponsors={sponsors} />
            }
            {
              quote &&
                <Quote {...quote} />
            }
            <Dates dates={dates} />
          </div>
        </div>
      </div>
    </div>
    <figure className='c-tour-background'>
      <Image
        image={mobileImage}
        width={818}
        sizes={`100vw`}
        lazyload
        className='u-hide-completely@md'
      />
      <Image
        image={image}
        width={1980}
        sizes={`100vw`}
        lazyload
        className='u-hide-completely-until@md'
      />
    </figure>
  </div>
)

Tour.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired,
  show: PropTypes.object.isRequired,
  quote: PropTypes.object,
  dates: PropTypes.array.isRequired
}

export default Tour
